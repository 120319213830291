document.addEventListener('DOMContentLoaded', function() {
    const $btn = $('#pull');
    const $menu = $('#menu-mainmenu');

    $btn.on('click', e => {
        e.preventDefault();
        $menu.slideToggle();
    });

    window.addEventListener('resize', () => {
        if ($(window).width() > 1280) {
            $menu.removeAttr('style');
        }
    });
});

window.Share = {
    vkontakte: function(purl, ptitle, pimg, text) {
        const url = `http://vkontakte.ru/share.php?url=${encodeURIComponent(purl)}&title=${encodeURIComponent(ptitle)}&description=${encodeURIComponent(text)}&image=${encodeURIComponent(pimg)}&noparse=true`;
        Share.popup(url);
    },

    odnoklassniki: function(purl, text) {
        const url = `http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st.comments=${encodeURIComponent(text)}&st._surl=${encodeURIComponent(purl)}`;
        Share.popup(url);
    },

    facebook: function(purl, ptitle, pimg, text) {
        const url = `http://www.facebook.com/sharer.php?s=100&p[title]=${encodeURIComponent(ptitle)}&p[summary]=${encodeURIComponent(text)}&p[url]=${encodeURIComponent(purl)}&p[images][0]=${encodeURIComponent(pimg)}`;
        Share.popup(url);
    },

    twitter: function(purl, ptitle) {
        const url = `http://twitter.com/share?text=${encodeURIComponent(ptitle)}&url=${encodeURIComponent(purl)}&counturl=${encodeURIComponent(purl)}`;
        Share.popup(url);
    },

    mailru: function(purl, ptitle, pimg, text) {
        const url = `http://connect.mail.ru/share?url=${encodeURIComponent(purl)}&title=${encodeURIComponent(ptitle)}&description=${encodeURIComponent(text)}&imageurl=${encodeURIComponent(pimg)}`;
        Share.popup(url)
    },

    google: function(purl) {
        const url = `https://plus.google.com/share?url=${purl}`;
        Share.popup(url);
    },

    me: function(purl, ptitle, pcontent) {
        const url = `mailto:?subject=${ptitle}&body=${pcontent} ${purl}`;
        Share.popup(url);
    },

    popup: function(url) {
        window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
    }
};

